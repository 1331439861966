__InitRollup__; // Replaced by Rollup

import './3-modules/lazyload.js';
import './3-modules/nav.js';
import './3-modules/vita.js';

function init() {
  import('./1-vendors/focus-visible-polyfill.js');
}

init();
